import React from 'react';

import Termly from 'src/components/Termly';
import Layout from 'src/layout/Layout';
import AnalyticsScript from 'src/components/Analytics';

const privacy = () => {
  return (
    <Layout>
      <Termly dataid="b47045e1-41a3-43ff-bcc3-c593bddf6b82" />
      <AnalyticsScript />
    </Layout>
  );
};

export default privacy;
